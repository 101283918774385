import React, { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ColorModeContext, darkTheme, lightTheme } from "./theme";
import { Layout, Login, Dashboard, Redirect, Customer } from "./components";
import { supabase } from "./utils";
import { User } from "@supabase/supabase-js";
import { Toaster } from "sonner";
import Transactions from "./components/pages/dashboard/components/transactions/transactions";

export default function App() {
  const [mode, setMode] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    localStorage.setItem("theme", mode);
    const bodyElement = document.body;
    bodyElement.style.colorScheme = mode;
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light")),
    }),
    []
  );

  const [user, setUser] = useState<User>();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        const currentUser = session?.user;
        setUser(currentUser);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
        <CssBaseline />

        <Toaster richColors />

        <Router>
          <Routes>
            <Route
              path="/"
              element={
                user ? (
                  <Layout>
                    <Outlet />
                  </Layout>
                ) : (
                  <Navigate to="/auth/login" replace />
                )
              }
            >
              {/* Assign all pages here */}
              <Route index element={<Dashboard />} />
              <Route path="customer" element={<Customer />} />
              <Route path="transactions" element={<Transactions />} />
            </Route>

            <Route
              path="/auth"
              element={!user ? <Outlet /> : <Navigate to="/" replace />}
            >
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/redirect" element={<Redirect />} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
