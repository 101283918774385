import React from "react";
import { CounterCard, CounterCardProps } from "../organisms";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

interface CardsGridItemProps extends CounterCardProps {
  link: string;
}

export default function CardsGridItem({ link, ...props }: CardsGridItemProps) {
  return (
    <Grid item xs sx={{ height: "auto", minWidth: "200px" }}>
      <Link to={link} style={{ textDecoration: "none" }}>
        <CounterCard {...props} />
      </Link>
    </Grid>
  );
}
