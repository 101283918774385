import React from "react";
import { Grid } from "@mui/material";

export default function CardsGridContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Grid
      container
      columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}
      spacing={2}
      wrap="nowrap"
      columnSpacing="8px"
      style={{ height: "100%", overflow: "auto" }}
    >
      {children}
    </Grid>
  );
}
