import { Card, Container } from "@mui/material";
import React from "react";

export const Footer = () => {
  return (
    <Card elevation={0} square>
      <Container
        style={{
          minHeight: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        ©2024 Henan Asset Management. All Right Reserved
      </Container>
    </Card>
  );
};
