import React from "react";
import CounterCardsGrid from "../../../../organisms/counterCardsGrid";
import { TableTransactions } from "./tableTransactions";
import { Stack } from "@mui/material";
import TransactionStatusCardsGrid from "./transactionStatusCardsGrid";

export default function Transactions() {
  return (
    <Stack gap={10}>
      <CounterCardsGrid />
      <Stack gap={6}>
        <TransactionStatusCardsGrid />
        <TableTransactions />
      </Stack>
    </Stack>
  );
}
