import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import React from "react";

// A custom theme for this app
export const lightTheme = createTheme({
  palette: {
    primary: { main: "#255AA1" },
    secondary: { main: "#147BD0" },
    error: { main: red.A400 },
    background: { default: "#F4F4F4" },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: 12,
  },
});

// A custom theme for this app
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#0A78BD" },
    secondary: { main: "#0A78BD" },
    error: { main: red.A400 },
    background: { default: "#000000" },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: 12,
  },
});

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});
