import React from "react";
import { Grid } from "@mui/material";
import {
  TotalAum,
  TotalCustomers,
  TotalProduct,
  TotalTransactions,
} from "../pages/dashboard/components";
import { Link } from "react-router-dom";

export default function CounterCardsGrid() {
  return (
    <Grid
      container
      columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}
      spacing={2}
      wrap="nowrap"
      columnSpacing="8px"
      style={{ height: "100%", overflow: "auto" }}
    >
      <Grid item xs sx={{ height: "auto", minWidth: "200px" }}>
        <Link to="/customer" style={{ textDecoration: "none" }}>
          <TotalCustomers />
        </Link>
      </Grid>

      <Grid item xs sx={{ height: "auto", minWidth: "200px" }}>
        <Link to="/transactions" style={{ textDecoration: "none" }}>
          <TotalTransactions />
        </Link>
      </Grid>

      <Grid item xs sx={{ height: "auto", minWidth: "200px" }}>
        <TotalAum />
      </Grid>

      <Grid item xs sx={{ height: "auto", minWidth: "200px" }}>
        <TotalProduct />
      </Grid>
    </Grid>
  );
}
