export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SERVICE_BASE_URL = `${BASE_URL}/${process.env.REACT_APP_BASE_SERVICE}`;

// #region DASHBOARD
export const DASHBOARD_URL = `${SERVICE_BASE_URL}/dashboard`;
// #endregion

// #region PRODUCT
export const PRODUCT_PERFORMANCE_URL = `${SERVICE_BASE_URL}/product-performance`;
export const PRODUCT_NAV_URL = `${SERVICE_BASE_URL}/product-nav`;
export const PRODUCT_DETAIL_URL = `${SERVICE_BASE_URL}/product-detail`;
// #endregion

// #region CUSTOMER PORTFOLIO
export const CUSTOMER_PORTFOLIO_URL = `${SERVICE_BASE_URL}/customer-portfolio`;
// #endregion

// #region TRANSACTION
export const TRANSACTION_URL = `${SERVICE_BASE_URL}/transaction`;
export const TOTAL_TRANSACTION_URL = `${SERVICE_BASE_URL}/total-transaction`;
// #endregion

// #region PARAMS
export const PARAM_PRODUCT_CODE = process.env.REACT_APP_CODE_PRODUCT;
export const PARAM_BROKER_CODE = process.env.REACT_APP_CODE_BROKER;
