import React, { useState, useEffect } from "react";
import axios from "axios";
import { CounterCard } from "../../../organisms/counterCard";
import { Inventory2 } from "@mui/icons-material";
import { toast } from "sonner";
import {
  DASHBOARD_URL,
  PARAM_BROKER_CODE,
  PARAM_PRODUCT_CODE,
} from "../../../../config/api";

export const TotalProduct: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<string>();

  useEffect(() => {
    axios
      .get(DASHBOARD_URL, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
        params: {
          codeBroker: PARAM_BROKER_CODE,
          productCode: PARAM_PRODUCT_CODE,
        },
      })
      .then(function (response) {
        const data = response.data?.product;
        setData(data?.total);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <CounterCard
        title={"Total Product"}
        value={data}
        icon={<Inventory2 color="primary" fontSize="large" />}
        isLoading={isLoading}
      />
    </div>
  );
};
