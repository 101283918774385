import { Button, ButtonProps, useTheme } from "@mui/material";
import React from "react";
import { convertHexToRGBA } from "../../utils";

export const AcrylicButton: React.FC<ButtonProps> = (props) => {
  const theme = useTheme();

  return (
    <Button
      variant="text"
      color="primary"
      type="submit"
      fullWidth
      sx={{
        textTransform: "none",
        fontWeight: "bold",
        backgroundColor: convertHexToRGBA(theme?.palette?.secondary?.main, 0.1),
      }}
      disableElevation
      {...props}
    />
  );
};
