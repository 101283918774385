import { Typography } from "@mui/material";
import React from "react";

export const Disclaimer = () => {
  const aksesKseiUrl = (
    <a href={"https://akses.ksei.co.id/"} target="_blank" rel="noreferrer">
      https://akses.ksei.co.id/
    </a>
  );

  const aksesKseiGuideUrl = (
    <a
      href={"https://www.ksei.co.id/education/akses-facility/"}
      target="_blank"
      rel="noreferrer"
    >
      https://www.ksei.co.id/education/akses-facility/
    </a>
  );

  const hpamEmail = (
    <a href={"mailto:cs@hpam.co.id"} target="_blank" rel="noreferrer">
      cs@hpam.co.id
    </a>
  );

  return (
    <div style={{ marginTop: "24px" }}>
      <Typography
        noWrap
        fontSize="12px"
        fontWeight="700"
        style={{ marginBottom: "9px", marginLeft: "5px" }}
      >
        Disclaimer:
      </Typography>
      <Typography
        fontSize="12px"
        style={{ marginBottom: "9px", marginLeft: "5px" }}
      >
        Laporan ini disediakan untuk memudahkan pemantauan kinerja investasi
        reksa dana. Pemantauan portofolio dapat juga dilakukan melalui sistem
        AKSes ({aksesKseiUrl}). Petunjuk penggunaan sistem AKSes dapat dilihat
        di ({aksesKseiGuideUrl}). Bilamana terdapat perbedaan antara laporan ini
        dengan Laporan Bank Kustodian dan/atau AKSes, Anda dapat melakukan
        konfirmasi melalui Customer Service kami dengan menghubungi via email ke{" "}
        {hpamEmail}.
      </Typography>
    </div>
  );
};
