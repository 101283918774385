import { Box, Container } from "@mui/material";
import React from "react";
import { Footer, Header } from "../organisms";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      gap="32px"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Container style={{ flexGrow: 1 }}>{children}</Container>
      <Footer />
    </Box>
  );
};
