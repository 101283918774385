import React, { useState, useEffect, useMemo } from "react";
import { IconButton, InputBase, Paper, Stack, useTheme } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { PARAM_BROKER_CODE, TRANSACTION_URL } from "../../../../../config/api";

/* eslint-disable react/prop-types */

type Transactions = {
  transactionCode: string;
  customer: string;
  transactionType: string;
  productCode: string;
  transactionDate: string;
  navDate: string;
  navValue: number;
  unit: number;
  nominal: number;
  status: string;
  remark: string | null;
  createdAt: string;
  goodfundAt: string;
  approvedAt: string;
};

type Pagination = {
  pageIndex: number;
  pageSize: number;
};

export const TableTransactions: React.FC = () => {
  const router = useNavigate();

  // Search Params
  const { search: queryParams } = useLocation();
  const query = new URLSearchParams(queryParams);

  // Retrieve status from query params
  const status = query.get("status");

  const [search, setSearch] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });

  const theme = useTheme();

  useEffect(() => {
    if (!status) {
      // Set default status to approved
      router(`/transactions?status=approved`);
    }
  }, []);

  const fetchData = async () => {
    if (!status) {
      return;
    }

    try {
      const response = await axios.get(TRANSACTION_URL, {
        params: {
          status: status.toUpperCase(), // This has to be uppercase to match the API
          codeBroker: PARAM_BROKER_CODE,
          search: search,
          startDate: startDate,
          endDate: endDate,
          page: pagination.pageIndex,
        },
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
      });

      const responseData = response.data;
      const customerData = responseData.data as Transactions[];

      return {
        data: customerData,
        totalData: responseData.total,
      };
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  const { data } = useQuery({
    enabled: !!status, // Only run the query if status is available
    queryFn: async () => await fetchData(),
    queryKey: [
      "transactionsByStatus",
      search,
      startDate,
      endDate,
      pagination,
      status,
    ],
  });

  const columns = useMemo<MRT_ColumnDef<Transactions>[]>(
    () => [
      {
        accessorKey: "customer",
        header: "Nama Nasabah",
      },
      {
        accessorKey: "transactionCode",
        header: "Kode Transaksi",
      },
      {
        accessorKey: "transactionDate",
        header: "Tanggal Transaksi",
        Cell: ({ cell }) => {
          const text = cell.getValue<string>();

          if (!text) return <span>-</span>;

          return <span>{dayjs(text).format("D MMMM YYYY")}</span>;
        },
      },
      {
        accessorKey: "unit",
        header: "Unit",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{text}</span>;
        },
      },
      {
        accessorKey: "nominal",
        header: "Nominal",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span style={{ color: "green" }}>Rp{text}</span>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "transactionType",
        header: "Jenis Transaksi",
      },
      {
        accessorKey: "approvedAt",
        header: "Tanggal Approval",
        Cell: ({ cell }) => {
          const text = cell.getValue<string>();

          if (!text) return <span>-</span>;

          return <span>{dayjs(text).format("D MMMM YYYY")}</span>;
        },
      },
      {
        accessorKey: "navValue",
        header: "NAV Sekarang",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{text}</span>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const table = useMaterialReactTable({
    columns,
    data: data?.data || [],
    onPaginationChange: setPagination,
    state: { pagination },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "0",
        backgroundColor: theme.palette.background.paper,
      },
    },
    muiTableHeadRowProps: {
      sx: { backgroundColor: theme.palette.background.paper },
    },
    muiTableBodyRowProps: {
      sx: { backgroundColor: theme.palette.background.paper },
    },
    muiBottomToolbarProps: {
      sx: { backgroundColor: theme.palette.background.paper },
    },
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined",
    },
    initialState: {},
    paginationDisplayMode: "pages",
    rowCount: data?.totalData,
    autoResetPageIndex: false,
    manualPagination: true,
    enableColumnFilters: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    layoutMode: "grid-no-grow",
    defaultColumn: {
      minSize: 20,
      maxSize: 140,
      size: 135,
    },
    enableRowNumbers: true,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack gap={2}>
        <Stack
          gap={2}
          direction="row"
          justifyContent="end"
          flexWrap="wrap"
          alignItems="center"
        >
          <Stack gap={0.5} direction="row" alignItems="center">
            <Paper
              elevation={0}
              sx={{
                padding: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputBase
                fullWidth
                type="date"
                placeholder="Start Date"
                style={{ padding: "0 8px" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setStartDate(event.target.value);
                }}
              />
            </Paper>

            <ArrowForward />

            <Paper
              elevation={0}
              sx={{
                padding: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputBase
                fullWidth
                type="date"
                placeholder="End Date"
                style={{ padding: "0 8px" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEndDate(event.target.value);
                }}
              />
            </Paper>
          </Stack>

          <Paper
            elevation={0}
            sx={{
              padding: "4px",
              display: "flex",
              alignItems: "center",
              width: 250,
            }}
          >
            <InputBase
              fullWidth
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              style={{ padding: "0 8px" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") fetchData();
              }}
            />
          </Paper>

          <IconButton
            type="button"
            aria-label="search"
            onClick={fetchData}
            style={{
              borderRadius: "3px",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SearchIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>

        <MaterialReactTable table={table} />
      </Stack>
    </LocalizationProvider>
  );
};
