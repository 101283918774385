import { TextField, TextFieldProps, useTheme } from "@mui/material";
import React from "react";

export const TextForm: React.FC<TextFieldProps> = (props) => {
  const theme = useTheme();

  return (
    <TextField
      fullWidth
      size="small"
      sx={{
        "& .MuiInputBase-root": {
          border: "none",
          backgroundColor: theme?.palette?.background?.default,
        },
        "& fieldset": { border: "none" },
      }}
      {...props}
    />
  );
};
