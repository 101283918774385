import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const Redirect = () => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};
