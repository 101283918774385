import {
  Grid,
  Skeleton,
  Card,
  useTheme,
  Typography,
  Tooltip,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { IconWrapper } from "..";
import React from "react";

export interface CounterCardProps {
  title?: React.ReactNode;
  value?: React.ReactNode;
  valueColor?: string;
  tooltipValue?: React.ReactNode;
  subscript?: React.ReactNode;
  subscriptColor?: string;
  description?: React.ReactNode;
  descriptionColor?: string;
  icon?: React.ReactNode;
  color?: string;
  isLoading?: boolean;
  active?: boolean;
}

export const CounterCard: React.FC<CounterCardProps> = ({
  title,
  value,
  valueColor,
  tooltipValue,
  subscript,
  subscriptColor,
  description,
  descriptionColor,
  icon,
  color,
  isLoading,
  active,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div style={{ height: "100%" }}>
      <Card
        sx={{
          height: "100%",
          padding: "12px",
          bgcolor: active ? "#143347" : "",
        }}
        elevation={0}
      >
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-between"
          height="100%"
        >
          <Grid item>
            <Typography
              noWrap
              fontSize={matches ? "12px" : "8px"}
              fontWeight="400"
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={matches ? "100px" : "50px"}
                />
              ) : (
                title
              )}
            </Typography>

            <Stack direction="row" alignItems="end">
              <Tooltip title={tooltipValue || value}>
                <Typography
                  noWrap
                  sx={{
                    color: active ? "white" : valueColor ?? "primary.main",
                  }}
                  fontSize={matches ? "32px" : "20px"}
                  fontWeight="700"
                >
                  {isLoading ? (
                    <Skeleton variant="text" animation="wave" width="50px" />
                  ) : (
                    value
                  )}
                </Typography>
              </Tooltip>

              <Typography
                noWrap
                color={subscriptColor}
                fontSize={matches ? "10px" : "6px"}
                fontWeight="700"
                style={{ marginBottom: "9px", marginLeft: "5px" }}
              >
                {subscript}
              </Typography>
            </Stack>

            <Typography
              noWrap
              color={descriptionColor}
              fontSize={matches ? "12px" : "8px"}
              fontWeight="700"
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={matches ? "100px" : "50px"}
                />
              ) : (
                description
              )}
            </Typography>
          </Grid>

          {icon && (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                svg: {
                  color: active
                    ? "white"
                    : color || theme?.palette?.secondary?.main,
                },
              }}
            >
              <IconWrapper color={color || theme?.palette?.secondary?.main}>
                {icon}
              </IconWrapper>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};
