import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AcrylicButton, TextForm } from "../../atoms";
import { toast } from "sonner";
import { supabase } from "../../../utils";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const signInFn = supabase.auth.signInWithOtp({
      email: email,
      options: {
        emailRedirectTo: `${location.origin}/auth/redirect`,
      },
    });

    toast.promise(signInFn, {
      loading: "Signing in...",
      success: "Please check your email.",
      error: (data) => {
        console.log(data);
        return "Failed to sign in.";
      },
    });

    setLoading(false);
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Card elevation={0} style={{ width: "400px" }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Stack gap="20px">
              <Stack gap="8px">
                <Typography style={{ fontWeight: "bold" }}>Email</Typography>

                <TextForm
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@mail.com"
                />
              </Stack>

              <AcrylicButton disabled={loading} type="submit">
                {loading ? <CircularProgress size={"20px"} /> : "Login"}
              </AcrylicButton>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};
