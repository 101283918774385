import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Graph } from "./components";
import { GraphFooter } from "../../molecules";
import { DownloadButton, Disclaimer } from "../../atoms";
import { toast } from "sonner";
import axios from "axios";
import CounterCardsGrid from "../../organisms/counterCardsGrid";
import { PARAM_PRODUCT_CODE, PRODUCT_DETAIL_URL } from "../../../config/api";

export const Dashboard: React.FC = () => {
  const [prospectusUrl, setProspectusUrl] = useState<string>();
  const [ffSheetUrl, setFfSheetUrl] = useState<string>();

  useEffect(() => {
    axios
      .get(PRODUCT_DETAIL_URL, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
        params: {
          productCode: PARAM_PRODUCT_CODE,
        },
      })
      .then(function (response) {
        const data = response.data;
        setProspectusUrl(data.prospectusFile);
        setFfSheetUrl(data.ffsFile);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  }, []);
  return (
    <div>
      <CounterCardsGrid />

      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}
        spacing={2}
        columnSpacing="8px"
        style={{ height: "100%" }}
      >
        <Grid item xs={4} sx={{ marginTop: "24px" }}>
          <Graph />
        </Grid>

        <Grid item xs={4} sx={{ marginBottom: "24px" }}>
          <GraphFooter />
        </Grid>

        <Grid xs item>
          <DownloadButton title="PROSPECTUS" linkRedirection={prospectusUrl} />
        </Grid>

        <Grid xs item>
          <DownloadButton
            title="FUND FACT SHEET"
            linkRedirection={ffSheetUrl}
          />
        </Grid>

        <Grid item xs={4} sx={{ width: "100%" }}>
          <Disclaimer />
        </Grid>
      </Grid>
    </div>
  );
};
