import { Button } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

interface DownloadButtonProps {
  title?: React.ReactNode;
  linkRedirection?: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  title,
  linkRedirection,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        style={{
          width: "100%",
          justifyContent: "flex-start",
          padding: "12px",
          paddingLeft: "18px",
        }}
        href={linkRedirection}
      >
        {title}
      </Button>
    </div>
  );
};
