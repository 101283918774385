import React, { useState, useEffect } from "react";
import axios from "axios";
import { CounterCard } from "../../../organisms/counterCard";
import { Savings } from "@mui/icons-material";
import { toast } from "sonner";
import {
  DASHBOARD_URL,
  PARAM_BROKER_CODE,
  PARAM_PRODUCT_CODE,
} from "../../../../config/api";

interface counterCardProps {
  value: string;
  description: string;
  descriptionColor?: string;
}

export const TotalAum: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<counterCardProps>();

  useEffect(() => {
    axios
      .get(DASHBOARD_URL, {
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
        params: {
          codeBroker: PARAM_BROKER_CODE,
          productCode: PARAM_PRODUCT_CODE,
        },
      })
      .then(function (response) {
        const data = response.data?.aum;
        setData({
          value: `${parseFloat(
            (parseInt(data?.total) / 1000000000).toString()
          ).toFixed(2)}M`,
          description: `${
            parseFloat(data?.percentage) > 0
              ? "+"
              : parseFloat(data?.percentage) < 0
              ? "-"
              : ""
          }${parseFloat(
            (parseInt(data?.changes) / 1000000000).toString()
          ).toFixed(2)}M (${parseFloat(data?.percentage).toFixed(2)}%)`,
          descriptionColor:
            parseFloat(data?.percentage) > 0
              ? "#119761"
              : parseFloat(data?.percentage) < 0
              ? "#D80027"
              : "",
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  }, []);

  return (
    <CounterCard
      title={"Total AUM"}
      value={data?.value}
      description={data?.description}
      icon={<Savings color="primary" fontSize="large" />}
      descriptionColor={data?.descriptionColor}
      isLoading={isLoading}
    />
  );
};
