import React, { useState, useEffect, useMemo } from "react";
import { IconButton, InputBase, Paper, Stack, useTheme } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { toast } from "sonner";
import { Customer } from "../customer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import {
  CUSTOMER_PORTFOLIO_URL,
  PARAM_BROKER_CODE,
  PARAM_PRODUCT_CODE,
} from "../../../../config/api";

/* eslint-disable react/prop-types */

type Customer = {
  namaNasabah: string;
  endingBalance: string;
  modal: string;
  date: string;
  pnl: string;
  nav: string;
  avgNav: string;
  endingUnit: string;
};
type ResponseData = {
  namaNasabah: string;
  endingBalance: string;
  modal: string;
  date: string;
  pnl: string;
  pnlPercentage: string;
  nav: string;
  avgNav: string;
  endingUnit: string;
};
type Pagination = {
  pageIndex: number;
  pageSize: number;
};

export const TableCustomer: React.FC = () => {
  const [data, setData] = useState<Customer[]>([]);
  const [search, setSearch] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalData, setTotalData] = useState<number>();
  const theme = useTheme();

  const columns = useMemo<MRT_ColumnDef<Customer>[]>(
    () => [
      {
        accessorKey: "namaNasabah",
        header: "Nama Nasabah",
      },
      {
        accessorKey: "endingBalance",
        header: "Nilai Sekarang",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{`Rp${text}`}</span>;
        },
      },
      {
        accessorKey: "modal",
        header: "Modal Investasi",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{`Rp${text}`}</span>;
        },
      },
      {
        accessorKey: "date",
        header: "Tanggal",
        Cell: ({ cell }) => {
          const text = cell.getValue<string>();
          return <span>{dayjs(text).format("D MMMM YYYY")}</span>;
        },
      },
      {
        accessorKey: "pnl",
        header: "PNL",
        Cell: ({ cell }) => {
          const [rawPnl, rawPnlPercentage] = cell.getValue<string>().split("#");
          const fontColor =
            parseFloat(rawPnl) > 0
              ? "green"
              : parseFloat(rawPnl) < 0
              ? "red"
              : "";
          const pnl = parseFloat(rawPnl)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          const pnlPercentage = (parseFloat(rawPnlPercentage) || 0).toFixed(2);
          const text = `Rp${pnl} (${pnlPercentage}%)`;
          return <span style={{ color: fontColor }}>{text}</span>;
        },
      },
      {
        accessorKey: "nav",
        header: "NAV Sekarang",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{text}</span>;
        },
      },
      {
        accessorKey: "avgNav",
        header: "Average NAV",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{text}</span>;
        },
      },
      {
        accessorKey: "endingUnit",
        header: "Jumlah Unit",
        Cell: ({ cell }) => {
          const text = parseFloat(cell.getValue<string>())
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return <span>{text}</span>;
        },
      },
    ],
    []
  );

  const fetchData = () => {
    axios
      .get(CUSTOMER_PORTFOLIO_URL, {
        params: {
          search: search,
          startDate: startDate,
          endDate: endDate,
          page: pagination.pageIndex,
          productCode: PARAM_PRODUCT_CODE,
          codeBroker: PARAM_BROKER_CODE,
        },
        headers: {
          "access-key": `${process.env.REACT_APP_ACCESS_KEY}`,
        },
      })
      .then(function (response) {
        const responseData = response.data;
        const customerData = responseData.data.map((item: ResponseData) => {
          const data: Customer = {
            avgNav: item.avgNav,
            date: item.date,
            endingBalance: item.endingBalance,
            endingUnit: item.endingUnit,
            modal: item.modal,
            namaNasabah: item.namaNasabah,
            nav: item.nav,
            pnl: `${item.pnl}#${item.pnlPercentage}`,
          };
          return data;
        });
        setData(customerData);
        setTotalData(responseData.total);
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const table = useMaterialReactTable({
    columns,
    data,
    onPaginationChange: setPagination,
    state: { pagination },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "0",
        backgroundColor: theme.palette.background.paper,
      },
    },
    muiTableHeadRowProps: {
      sx: { backgroundColor: theme.palette.background.paper },
    },
    muiTableBodyRowProps: {
      sx: { backgroundColor: theme.palette.background.paper },
    },
    muiBottomToolbarProps: {
      sx: { backgroundColor: theme.palette.background.paper },
    },
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined",
    },
    initialState: {},
    paginationDisplayMode: "pages",
    rowCount: totalData,
    autoResetPageIndex: false,
    manualPagination: true,
    enableColumnFilters: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    layoutMode: "grid-no-grow",
    defaultColumn: {
      minSize: 20,
      maxSize: 140,
      size: 135,
    },
    enableRowNumbers: true,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack gap={2}>
        <Stack
          gap={2}
          direction="row"
          justifyContent="end"
          flexWrap="wrap"
          alignItems="center"
        >
          <Stack gap={0.5} direction="row" alignItems="center">
            <Paper
              elevation={0}
              sx={{
                padding: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputBase
                fullWidth
                type="date"
                placeholder="Start Date"
                style={{ padding: "0 8px" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setStartDate(event.target.value);
                }}
              />
            </Paper>

            <ArrowForward />

            <Paper
              elevation={0}
              sx={{
                padding: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputBase
                fullWidth
                type="date"
                placeholder="End Date"
                style={{ padding: "0 8px" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEndDate(event.target.value);
                }}
              />
            </Paper>
          </Stack>

          <Paper
            elevation={0}
            sx={{
              padding: "4px",
              display: "flex",
              alignItems: "center",
              width: 250,
            }}
          >
            <InputBase
              fullWidth
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              style={{ padding: "0 8px" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") fetchData();
              }}
            />
          </Paper>

          <IconButton
            type="button"
            aria-label="search"
            onClick={fetchData}
            style={{
              borderRadius: "3px",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SearchIcon style={{ color: "white" }} />
          </IconButton>
        </Stack>

        <MaterialReactTable table={table} />
      </Stack>
    </LocalizationProvider>
  );
};
