import React, { PropsWithChildren } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { convertHexToRGBA } from "../../utils";

export const IconWrapper: React.FC<PropsWithChildren<{ color?: string }>> = ({
  color,
  children,
}) => {
  const theme = useTheme();
  const defaultColor = theme?.palette?.mode === "light" ? "#000" : "#fff";
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: matches ? "62px" : "44px",
        height: matches ? "62px" : "44px",
        borderRadius: "4px",
        backgroundColor: convertHexToRGBA(color || defaultColor, 0.1),
        color: color || defaultColor,
      }}
    >
      {children}
    </div>
  );
};
